import React from "react";
import PropTypes from "prop-types";
// import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Container, Grid, Divider} from "@material-ui/core";
import FixedNavbar from "../components/FixedNavbar";
import Footer from "../components/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import showdown from 'showdown';


// eslint-disable-next-line 
export const FAQsPageTemplate = ({ 
    heading,
    subHeading,
     }) => {

    // const Faq = ({faq, index}) => {

    //   return (
    //     <>
    //     <Grid item key={index} xs={12} sm={12} className="featureContainer">
    //       <p className="faqQuestion">
    //         {/* {faq.question} */}
    //       </p>
    //       <p className="bodyText"> 
    //         {/* {faq.answer} */}
    //       </p>
    //     </Grid> 
    //     </>
    //   )
    // };
  

  return (
    <>
      <FixedNavbar />
      <section className="stay"  style={{marginTop:"5%"}}>
        <Container maxWidth="md">
          <Grid container spacing={2} className="faqHeaderContainer">
            <Grid item xs={12} sm={12} md={12}>
              <Divider className="sectionHeaderLine" />
              <h2>FAQs</h2>
               <p className="bodyText">desc</p>
               {heading && (
                <h2>{heading}</h2>
               )}
               {subHeading && (
                <p className="bodyText">{subHeading}</p>
               )}
            </Grid>
          </Grid>
          <Grid container>
            {/* {faqs.map((faq, ind) => (
              <Faq faq={faq} index={ind} />
            ))} */}
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
};

FAQsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const FAQsPage = ({ data }) => {

  return (
    <Layout>
      {/* <FAQsPageTemplate/> */}
    </Layout>
  );
};

FAQsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FAQsPage;

// heading
// subheading
// faq {
//   question
//   answer
// }

// export const FAQsPageQuery = graphql`
//   query FAQsPageTemplate {
//     markdownRemark(frontmatter: {templateKey: {eq: "faqs-page"}}) {
//       frontmatter {
//         heading
//         subheading
//         faq {
//           question
//           answer
//         }
//       }
//     }
//   }
// `;
